<template>
  <div class="threeImgBox">
    <div
      class="threeImg"
      @mousedown="start()"
      @mousemove="handleMouseMove($event)"
      @mouseup="end()"
      @mousewheel.prevent="rollImg"
      @touchstart="start()"
      @touchmove="handleTouchMove($event)"
      @touchend="end()"
      ref="image"
    >
      <img
        :src="item"
        alt=""
        v-for="(item, index) in list"
        :key="index"
        :ref="getRef_01"
        v-show="data.index == index"
      />
    </div>
    <!-- <div>{{ list }}</div> -->
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import api from "@/api/index";
import axios from "axios";
import { useRoute } from "vue-router";
import JSzip from "jszip";
import JSZipUtils from "jszip-utils";
let zip = new JSzip();

let ref_01 = ref([]);

// 压缩包中的图片数组
const image = ref(null);
let route = new useRoute();
let path =
  "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/netdisk/" +
  route.query.value2;
//   压缩包中的图片数量
let number = route.query.value1;
let data = reactive({
  // 图片下标
  index: 0,
  zipName: route.query.value2,
  //   number: 3,
  imgNumber: 0,
  imgPath: "",
  timer_debounce: null,
  drag: false,
  x: "",
  y: "",
  dirX: "0",
  dirY: "0",
  openMouse: "0",
  direction: {
    x: "",
    y: "",
  },
  count: 0,
  count2: 0,
  topDown: 0,
});

let initialDistance = 0;
// 初始屏幕宽度
let baseWidth = window.screen.width;
let imageWidth = ref(baseWidth);

const getRef_01 = (el) => {
  if (el) {
    ref_01.value.push(el);
  }
};
const start = () => {
  data.drag = true;
};
const end = () => {
  data.drag = false;
};
let img = reactive({
  x: 0,
  y: 0,
});
let imgPosition = reactive({
  x: 0,
  y: 0,
});

// 图片列表
let list = ref([]);

onMounted(() => {
  // 获取图片列表
  console.log(path);
  setTimeout(() => {
    getList();
  }, 500);
});

// 获取压缩包中的图片
const getList = () => {
  JSZipUtils.getBinaryContent(path, function (err, data) {
    if (err) throw err;

    // 解析Zip文件
    zip.loadAsync(data).then(function (zip) {
      console.log("data", data);
      // 读取Zip中的文件
      for (let i = 0; i < number; i++) {
        console.log("i", i);
        zip
          .file(i + ".jpg")
          .async("base64")
          .then((res) => {
            let imgBase64 = "data:image/jpeg;base64, " + res;
            list.value.push(imgBase64);
          });
      }
    });
  });
};

const moveImg = (e) => {
  let x = e.clientX;
  let y = e.clientY;
  let left = x - e.target.offsetLeft;
  let top = y - e.target.offsetTop;
  document.onmousemove = (ev) => {
    e.target.style.left = ev.clientX - left + "px";
    e.target.style.top = ev.clientY - top + "px";
  };
  document.onmouseup = () => {
    document.onmousemove = null;
  };
};

// 缩放图片
const rollImg = (e) => {
  for (let i = 0; i < data.imgNumber; i++) {
    let transform = ref_01.value[i].style.transform;
    let zoom =
      transform.indexOf("scale") != -1
        ? +transform.split("(")[1].split(")")[0]
        : 1;
    zoom += e.wheelDelta / 1200;
    if (zoom > 0.5 && zoom < 3) {
      ref_01.value[i].style.transform = "scale(" + zoom + ")";
      if (zoom < 1 || zoom == 1) {
        ref_01.value[i].style.marginTop = 0;
      } else if (zoom > 1 && zoom < 2) {
        ref_01.value[i].style.marginTop = zoom * 100 + "px";
      } else if (zoom >= 2 && zoom < 3) {
        ref_01.value[i].style.marginTop = zoom * 250 + "px";
      }
    }
  }
};
const handleMouseMove = (event) => {
  if (data.drag == true) {
    // 判断鼠标方向 左右
    if (data.dirX < event.offsetX) {
      data.dirX = event.offsetX;
      data.direction.x = "右";
      data.count = data.count - 1;
    } else {
      data.dirX = event.offsetX;
      data.direction.x = "左";
      data.count = data.count + 1;
    }

    // 取整
    // data.index = Number.parseInt(data.count);
    // x限位 首位循环
    if (data.count < 0) {
      data.count = number - 1;
    }
    if (data.count > number - 1) {
      data.count = 0;
    }
    data.index = data.count;
    console.log(data.index);
  }
};

// 移动端触屏事件
const handleTouchMove = (event) => {
  // 旋转
  if (data.drag == true) {
    // 判断鼠标方向 左右
    if (data.dirX < event.targetTouches[0].pageX) {
      data.dirX = event.targetTouches[0].pageX;
      data.direction.x = "右";
      data.count = data.count - 0.5;
    } else {
      data.dirX = event.targetTouches[0].pageX;
      data.direction.x = "左";
      data.count = data.count + 0.5;
    }

    // x限位 首位循环
    if (data.count < 0) {
      data.count = number - 1;
    }
    if (data.count > number - 1) {
      data.count = 0;
    }

    // 取整
    data.index = Number.parseInt(data.count);
    console.log(data.index);
  }
};

// // 移动端触屏事件
// const handleTouchMove = (event) => {
//   if (event.touches.length === 2) {
//     // 缩放
//     const currentDistance = Math.hypot(
//       event.touches[0].pageX - event.touches[1].pageX,
//       event.touches[0].pageY - event.touches[1].pageY
//     );
//   } else {
//     // 旋转
//     if (data.drag == true) {
//       // 判断鼠标方向 左右
//       if (data.dirX < event.targetTouches[0].pageX) {
//         data.dirX = event.targetTouches[0].pageX;
//         data.direction.x = "右";
//         data.count = data.count - 1;
//       } else {
//         data.dirX = event.targetTouches[0].pageX;
//         data.direction.x = "左";
//         data.count = data.count + 1;
//       }

//       // 取整
//       // data.index = Number.parseInt(data.count);
//       // x限位 首位循环
//       if (data.count < 0) {
//         data.count = number - 1;
//       }
//       if (data.count > number - 1) {
//         data.count = 0;
//       }
//       data.index = data.count;
//       console.log(data.index);
//     }
//   }
// };
</script>

<style lang="less" scoped>
.threeImgBox {
  // width: 100%;
  // height: 100%;
  width: 100%;
  //   height: 90%;
  // height: 800px;
  // position: fixed;
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  //   border: 1px solid red;
  @media only screen and (max-width: 800px) {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
  }

  .threeImg {
    width: 60%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    @media only screen and (max-width: 800px) {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: black;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      -webkit-user-drag: none;

      -moz-user-drag: none;

      -ms-user-drag: none;

      //   user-drag: none;
      //   border: 1px solid red;
    }
  }
}
</style>
